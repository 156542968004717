<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <listitems
          :list="agents_list"
          :qDelete="Qdelete"
          :qCreate="Qcreate"
          :qUpdate="Qupdate"
          :Get_suf="'Agent'"
          :title="'Agent'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Agents'"
          :sheetname="'Agent'"
          :key="klist"
          :showstd="true"
          @rowselect="AgentChange"
          :add="true"
          :Update="true"
          :del="true"
          :del_disable="agent.nbdem > 0 || agent.nbpren > 0"
          :laoding="loading"
          @change="refresh"
          @add="refresh"
          @delete="refresh"
        >
        </listitems>
      </v-col>
    </v-row>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}
import CREATE_AGENT from "../graphql/Production/CREATE_AGENT.gql";
import UPDATE_AGENT from "../graphql/Production/UPDATE_AGENT.gql";
import DELETE_AGENT from "../graphql/Production/DELETE_AGENT.gql";
import AGENTS from "../graphql/Production/AGENTS.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },

  data: () => ({
    loading: false,
    klist: 400,
    kart: 500,
    agent: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Date creation",
        value: "created_at",
        selected: true,
      },
      {
        text: "Matricule",
        value: "matricule",
        edit: true,
        rules: [(v) => !!v || "Matricule obligatoire"],
        type: "text",
        sm: 3,
        md: 3,
        selected: true,
      },
      {
        text: "Nom",
        value: "nom",
        rules: [(v) => !!v || "Nom obligatoire"],
        slot: "href",
        edit: true,
        type: "text",
        sm: 9,
        md: 9,
        selected: true,
      },
      {
        text: "Fonction",
        value: "fonction",
        selected: true,
      },
      {
        text: "Fonction",
        value: "fonction_id",
        edit: true,
        rules: [(v) => !!v || "Fonction obligatoire"],
        type: "autocomplete",
        sm: 9,
        md: 9,
        select_list: [],
        item_value: "id",
        item_text: "fonction",
        hiden: true,
      },
      {
        text: "Structure",
        value: "structure",
        selected: true,
      },
      {
        text: "Structure",
        value: "struct_id",
        edit: true,
        rules: [(v) => !!v || "Structure obligatoire"],
        type: "autocomplete",
        sm: 9,
        md: 9,
        select_list: [],
        item_value: "id",
        item_text: "label",
        hiden: true,
      },
    ],
    agents_list: [],
    articles_list: [],
    fonctions: [],
    structures: [],
    art_loading: false,
  }),
  watch: {},
  computed: {
    Qcreate() {
      return CREATE_AGENT;
    },
    Qupdate() {
      return UPDATE_AGENT;
    },
    Qselectall() {
      return AGENTS;
    },
    Qdelete() {
      return DELETE_AGENT;
    },
  },
  created() {},

  mounted() {
    this.get_data();
  },

  methods: {
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    refresh: debounce(function () {
      this.get_data();
    }, 1000),
    async get_data() {
      this.loading = true;
      await this.$apollo
        .query({
          query: AGENTS,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.agents_list = data.data.agents.sort(this.sortBy("nom"));
          this.fonctions = data.data.fonctions;
          this.structures = data.data.structures;
          this.headers[5].select_list = this.fonctions;
          this.headers[7].select_list = this.structures;
          this.klist++;
          this.loading = false;
        });
    },
    async requette(query, v) {
      let r;
      this.art_loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.art_loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.art_loading = false;
        });
      return r;
    },
    async AgentChange(item) {
      this.agent = item;
    },
  },
};
</script>
